.index-page{
  .title, .card-body {
    text-align: center;
  }

  .certification-row {
    text-align: center;
  }

  .certification-img {
    max-width: 223px;
  }

  .info.plugin-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding: 20px;
    border: 1px solid transparent;
    .btn + .btn {
      margin: 10px 0 0 0;
    }

    .plugin-icon-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.imprint-page{
    background-image: url("../../assets/img/dots.png");
    background-size: contain;
    background-color: $background-black;

    .link{
        font-size: 10px;
        color: $white;
        text-decoration: none;
    }

    .imprint-bg{
      height: 700px;
      background: linear-gradient(to bottom, $transparent-bg 0%, $card-black-background 100%);
      width: 700px;
      position: relative;
      right: -600px;
      top: -750px;
      border-radius: 20%;
      transform: rotate(-15deg);
    }

    .square{
      position: absolute;
      transition: 0.5s ease-out;
      border-radius: 2px;
      background-size: 100%;
      border-radius: 20%;
    }

    .square-1{
      height: 300px;
      width: 300px;
      background-image: url("../../assets/img/square1.png");
      top: 175px;
      right: 150px;
      opacity: 0.8;
    }

    .square-2{
      height: 120px;
      width: 120px;
      background-image: url("../../assets/img/square2.png");
      opacity: 0.9;
      top: 310px;
      right: 390px;
    }

    .square-3{
      width: 200px;
      height: 200px;
      background-image: url("../../assets/img/square3.png");
      opacity: 0.3;
      top: -50px;
      right: 400px;
    }

    .square-4{
      height: 200px;
      width: 200px;
      background-image: url("../../assets/img/square4.png");
      right: -50px;
      top: 525px;
    }

    .square-5{
      height: 150px;
      width: 150px;
      background-image: url("../../assets/img/square5.png");
      opacity: 0.5;
      top: 500px;
      right: 470px;
    }

    .square-6{
      height: 70px;
      width: 70px;
      background-image: url("../../assets/img/square5.png");
      opacity: 0.9;
      top: 105px;
      right: 20px;
    }

    .square-7{
      height: 200px;
      width: 200px;
      background-image: url("../../assets/img/square5.png");
      left: -100px;
      top: 100px;
      opacity: .4;
    }

    .square-8{
      height: 100px;
      width: 100px;
      background-image: url("../../assets/img/square1.png");
      left: 300px;
      top: 400px;
      opacity: .4;
    }
}

.privacy-policy-page{
  background-image: url("../../assets/img/dots.png");
  background-size: contain;
  background-color: $background-black;

  .page-header{
    max-height: unset;
  }

  .link{
    font-size: 10px;
    color: $white;
    text-decoration: none;
  }

  .privacy-policy-bg{
    height: 700px;
    background: linear-gradient(to bottom, $transparent-bg 0%, $card-black-background 100%);
    width: 700px;
    position: relative;
    right: -600px;
    top: -750px;
    border-radius: 20%;
    transform: rotate(-15deg);
  }

  .square{
    position: absolute;
    transition: 0.5s ease-out;
    border-radius: 2px;
    background-size: 100%;
    border-radius: 20%;
  }

  .square-1{
    height: 300px;
    width: 300px;
    background-image: url("../../assets/img/square1.png");
    top: 175px;
    right: 150px;
    opacity: 0.8;
  }

  .square-2{
    height: 120px;
    width: 120px;
    background-image: url("../../assets/img/square2.png");
    opacity: 0.9;
    top: 310px;
    right: 390px;
  }

  .square-3{
    width: 200px;
    height: 200px;
    background-image: url("../../assets/img/square3.png");
    opacity: 0.3;
    top: -50px;
    right: 400px;
  }

  .square-4{
    height: 200px;
    width: 200px;
    background-image: url("../../assets/img/square4.png");
    right: -50px;
    top: 525px;
  }

  .square-5{
    height: 150px;
    width: 150px;
    background-image: url("../../assets/img/square5.png");
    opacity: 0.5;
    top: 500px;
    right: 470px;
  }

  .square-6{
    height: 70px;
    width: 70px;
    background-image: url("../../assets/img/square5.png");
    opacity: 0.9;
    top: 105px;
    right: 20px;
  }

  .square-7{
    height: 200px;
    width: 200px;
    background-image: url("../../assets/img/square5.png");
    left: -100px;
    top: 100px;
    opacity: .4;
  }

  .square-8{
    height: 100px;
    width: 100px;
    background-image: url("../../assets/img/square1.png");
    left: 300px;
    top: 400px;
    opacity: .4;
  }
}
